import { graphql } from 'gatsby';
import { object } from 'prop-types';
import { Step } from '../components/step-section';
import { Section } from '../components/common/practice-child-step/section';
import { LegalJourney } from '../components/legal-journey';
import { Appointment } from '../components/legal-journey-appointment';
import * as styles from './templates.module.scss';

function PracticeChildStep4Template({ data }) {
  const stepData = data?.wpPage?.wpParent?.node;
  const currentUri = stepData.uri;

  return (
    <main className={styles.pageStyles}>
      <Section data={stepData} />
      <Step step={4} text="Make an appointment" />
      <LegalJourney steps={4} currentStep={4} uri={currentUri} />
      <Appointment
        data={data?.allWpLocation?.nodes}
        text="Select one of our Colorado locations to request a case assessment"
      />
    </main>
  );
}

export const query = graphql`
  query wpChildStepTemplate4($uri: String) {
    wpPage(uri: {eq: $uri}) {
      title
      uri
      wpParent {
        node {
          ... on WpPage {
            uri
            template {
              ... on WpTemplate_PracticeChild {
                practiceChildConfiguration {
                  eaPracticeShortName
                }
              }
            }
            wpParent {
              node {
                ... on WpPage {
                  title
                  uri
                }
              }
            }
          }
        }
      }
      ...SeoPage
    }
    allWpLocation(sort: {fields: title}) {
      nodes {
        title
        uri
        featuredImage {
          node {
            gatsbyImage(width:400)
          }
        }
        locationSingleConfiguration {
          eaLocationPhone
        }
      }
    }
  }
`;

PracticeChildStep4Template.propTypes = {
  data: object,
};

PracticeChildStep4Template.defaultProps = {
  data: {},
};

export default PracticeChildStep4Template;
export { Head } from '../components/seo/seo';
